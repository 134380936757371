import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { reactive, ref, onMounted } from "vue";
export default {
  __name: 'maskLayer',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: "30vh"
    },
    background: {
      type: String,
      default: "rgba(0, 0, 0, 0.7)"
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  emits: ["onChange"],
  setup(__props, {
    emit
  }) {
    const props = __props;
    function close() {
      emit("close");
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_Teleport, {
        to: "body",
        disabled: !__props.appendToBody
      }, [_withDirectives(_createElementVNode("div", _mergeProps(_ctx.$attrs, {
        class: "maskLayer-overlay",
        style: {
          background: __props.background
        },
        onClick: close
      }), [_createElementVNode("div", {
        class: "maskLayer-content",
        style: _normalizeStyle({
          marginTop: props.center ? 0 : props.top,
          alignItems: props.center ? 'center' : 'stretch'
        })
      }, [_renderSlot(_ctx.$slots, "default")], 4)], 16), [[_vShow, __props.modelValue]])], 8, ["disabled"]);
    };
  }
};